import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { ValueCard } from "../../models/CardProps";
import { ServiceSearchState } from "../../models/serviceSearchState";
import { StateStorage } from "../../models/stateStorage";
import { existsServiceAccount } from "../actions/service-account.actions";

const initialState: ServiceSearchState = {
  hasError: false,
  isLoading: false,
  userExist: false,
  filterSelected: "Usuarios",
};

export const serviceSearchSlice = createSlice({
  name: "serviceSearch",
  initialState,
  reducers: {
    setResetVars: (state) => {
      state.hasError = false;
      state.isLoading = false;
      state.userExist = false;
    },
    onFilterSelectedChanged: (state, action: PayloadAction<ValueCard>) => {
      state.filterSelected = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(existsServiceAccount.pending, (state, action) => {
        state.isLoading = true;
        state.hasError = false;
      })
      .addCase(existsServiceAccount.fulfilled, (state, action) => {
        state.userExist = true;
        state.hasError = false;
        state.isLoading = false;
      })
      .addCase(existsServiceAccount.rejected, (state, action) => {
        state.isLoading = false;
        state.hasError = true;
      });
  },
});

export const { setResetVars, onFilterSelectedChanged } =
  serviceSearchSlice.actions;

export const isLoading = (state: StateStorage) => state.serviceSearch.isLoading;
export const hasError = (state: StateStorage) => state.serviceSearch.hasError;
export const userExist = (state: StateStorage) => state.serviceSearch.userExist;

export default serviceSearchSlice.reducer;
