import {
  handleRegistryMicrofront,
  initEnviroment,
  NavSidebar,
  PrivateRoute,
  ThemeConfig,
} from "@enerbit/base";
import { useEffect } from "react";
import { Provider } from "react-redux";
import "./assets/css/search.scss";
import Search from "./pages/Search/SearchBitoria";
import { store } from "./store/store";

// Inicializa el entorno
initEnviroment({
  baseUrl: process.env.REACT_APP_BASE_URL,
});

export default function Root() {
  useEffect(() => {
    // Montar el sidebar al iniciar el componente
    handleRegistryMicrofront({
      microName: "sidebar",
      customProps: { sidebarId: "single-spa-application:sidebar" },
      importName: "@enerBit/sidebar",
    });
  }, []);

  return (
    <Provider store={store}>
      <ThemeConfig>
        <PrivateRoute>
          <>
            {/* <div id="single-spa-application:sidebar"></div> */}
            <NavSidebar />
            <section className="Container-root">
              <Search />
            </section>
          </>
        </PrivateRoute>
      </ThemeConfig>
    </Provider>
  );
}
