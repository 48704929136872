import { createAsyncThunk } from "@reduxjs/toolkit";
import * as singleSpa from "single-spa";
import { ValueCard } from "../../models/CardProps";

export type ExistsServiceAccountProps = {
  pattern: string;
  serviceTypeName: ValueCard;
};

export const existsServiceAccount = createAsyncThunk(
  "serviceAccount/smart",
  async (payload: ExistsServiceAccountProps) => {
    const params = new URLSearchParams();
    params.set("pattern", payload.pattern);
    params.set("service-type", payload.serviceTypeName);
    singleSpa.navigateToUrl(`/#/coincidences?${params.toString()}`);
  }
);
