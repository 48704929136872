import {
  ApartmentOutlinedIcon,
  BoltOutlinedIcon,
  Box,
  CircularProgress,
  Grid,
  IconButton,
  PersonOutlineOutlinedIcon,
  SearchIcon,
  TextField,
  useFormik,
  yup,
} from "@enerbit/base";
import FlipIcon from "@mui/icons-material/Flip";
import { useDispatch, useSelector } from "react-redux";
import { existsServiceAccount } from "../../features/actions/service-account.actions";
import { isLoading, onFilterSelectedChanged } from "../../features/slices/serviceSearchSlice";
import { CardProps } from "../../models/CardProps";
import { InitialValues } from "../../models/serviceSearchState";
import { StateStorage } from "../../models/stateStorage";
import { AppDispatch } from "../../store/store";

const SearchBitoria = () => {
  const isLoadingSearch = useSelector(isLoading);
  const { filterSelected } = useSelector((state: StateStorage) => state.serviceSearch);

  const dispatch = useDispatch<AppDispatch>();

  const iconAdorment = (
    <>
      <IconButton>
        <SearchIcon />
      </IconButton>
    </>
  );

  let initialValues: InitialValues = {
    param: "",
  };

  const validationSchema = yup.object({
    param: yup.string().required("Campo de búsqueda es requerido"),
  });

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    onSubmit: (values: InitialValues) => {
      if (filterSelected == "Medidores") {
        console.log("Aún no hemos implementado está busqueda");
        return;
      }
      dispatch(
        existsServiceAccount({
          pattern: values.param,
          serviceTypeName: filterSelected,
        })
      );
    },
  });

  const filtersSelected: CardProps[] = [
    {
      value: "Usuarios",
      icon: <PersonOutlineOutlinedIcon className="Icon-search-filter" />,
    },
    {
      value: "Predios",
      icon: <ApartmentOutlinedIcon className="Icon-search-filter" />,
    },
    {
      value: "Ener. Convencional",
      icon: <BoltOutlinedIcon className="Icon-search-filter" />,
    },
    {
      value: "Fronteras",
      icon: <FlipIcon className="Icon-search-filter" />,
    },
  ];

  const _placeholderSearchTextField = (): string => {
    if (filterSelected == "Ener. Convencional") {
      return "Buscar por NIU o Código interno";
    }
    if (filterSelected == "Usuarios") {
      return "Ej: usuario@enerbit.co";
    }
    if (filterSelected == "Predios") {
      return "Buscar por dirección";
    }
    if (filterSelected == "Fronteras") {
      return "Buscar por código, ID de frontera o fecha de registro en AAAA-MM-DD";
    }
    return "";
  };

  return (
    <Box
      sx={{
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
      }}
    >
      <Box>
        <Box className="Header-main">¿Qué deseas buscar?</Box>
        <Box className="Header-secondary">Selecciona el tipo de entidad que deseas buscar</Box>
        <Box className="Box-search-main">
          <Box className="Box-search-text">Selecciona lo que estás buscando</Box>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Grid container spacing={2} sx={{ justifyContent: "center" }}>
              {filtersSelected.map((value, index) => (
                <Box
                  key={index}
                  className={value.value == filterSelected ? "Card-search-filter selected" : "Card-search-filter"}
                  onClick={() => dispatch(onFilterSelectedChanged(value.value))}
                >
                  <Box>{value.icon}</Box>
                  <Box>{value.value}</Box>
                </Box>
              ))}
            </Grid>
          </Box>
          <Box className="TextField-without-border-radius" sx={{ marginTop: "40px" }}>
            <form onSubmit={formik.handleSubmit}>
              <TextField
                InputProps={{
                  startAdornment: iconAdorment,
                }}
                placeholder={_placeholderSearchTextField()}
                name="param"
                sx={{
                  width: "100%",
                  borderRadius: "14px",
                  background: "white",
                }}
                value={formik.values.param}
                onChange={formik.handleChange}
                error={formik.touched.param && Boolean(formik.errors.param)}
                helperText={formik.touched.param && formik.errors.param}
              />
            </form>
            {isLoadingSearch && <CircularProgress sx={{ marginTop: "20px" }} />}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default SearchBitoria;
